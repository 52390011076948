import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

export default function Personal({ lang }) {
  const data = useStaticQuery(graphql`
    query {
      sv: wpPage(databaseId: { eq: 15 }) {
        id
        personal {
          personer {
            titel
            telefon
            namn
            epost
            bild {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      en: wpPage(databaseId: { eq: 2569 }) {
        id
        personal {
          personer {
            titel
            telefon
            namn
            epost
            bild {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  const personsEN = data.en.personal.personer
  const personsSE = data.sv.personal.personer
  var persons = []
  lang === "en" ? (persons = personsEN) : (persons = personsSE)
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
        {persons.map((person, i) => {
          return <Person key={i} person={person} />
        })}
      </div>
    </div>
  )
}

function Person({ person }) {
  const { namn, titel, epost, bild } = person
  const img = getImage(bild?.localFile.childImageSharp)
  return (
    <div className="flex flex-col gap-4  ">
      <div className="bg-primary">
        {img ? (
          <GatsbyImage image={getImage(img)} alt="" />
        ) : (
          <StaticImage
            src="../../images/blank-profile.webp"
            alt=""
            className="opacity-60"
          />
        )}
      </div>
      <div>
        <p className="font-bold">{namn}</p>
        <p className="text-sm">{titel}</p>
        <p className="text-sm">
          <a href={`mailto:${epost}`}>Epost</a>
        </p>
      </div>
    </div>
  )
}
