import React from "react"
import InforBesokMenu from "../info/InforBesokMenu"

import FeaturedImage from "./FeaturedImage"
import HeaderFeaturedImage from "./HeaderFeaturedImage"
import Logo from "./Logo"
import PageHeader from "./PageHeader"
import Personal from "./Personal"

export default function SinglePage({ data, lang }) {
  const { content, title, featuredImage, databaseId, parentDatabaseId } =
    data.wpPage

  return (
    <div>
      {featuredImage && (
        <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
      )}
      {databaseId === 1243 ||
      databaseId === 2656 ||
      parentDatabaseId === 1243 ||
      parentDatabaseId === 2656 ? (
        <InforBesokMenu lang={lang} />
      ) : null}
      <div className="wp-page max-w-4xl min-h-screen mx-auto px-4 lg:px-8 py-8 lg:py-16">
        <div className="flex flex-col gap-4 lg:gap-8">
          {!featuredImage && <PageHeader text={title} />}
          <div className="">
            {databaseId === 15 || databaseId === 2569 ? (
              <div>
                <Personal lang={lang} />
              </div>
            ) : null}
          </div>
          <div className="flex flex-col lg:flex-row gap-8 ">
            {/* Primary column */}
            <div className="flex-1 overflow-hidden space-y-8">
              <div>
                <div
                  className="wp-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>{" "}
              {featuredImage && <FeaturedImage image={featuredImage} />}
              {/* {caption && (
                <div className="text-sm">
                  <div dangerouslySetInnerHTML={{ __html: caption }} />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="gallery kongress-mess" />
    </div>
  )
}
