import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import SinglePage from "../components/common/SinglePage"

export default function PageTemplate({ data }) {
  const page = data.wpPage
  const contentTrimmed = page.content
    ? page.content.substring(0, 150).replace(/<[^>]*>?/gm, "")
    : ""
  const translation = data.wpPage.translations[0]?.uri

  const metaDescription = page.seo.metaDesc || contentTrimmed
  const lang = page.language.slug
  return (
    <div className="hej">
      <Seo title={data?.wpPage.title} description={metaDescription} />

      <Layout lang={lang} translation={translation}>
        {data.databaseId}
        <SinglePage data={data} lang={lang} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      databaseId
      content
      seo {
        metaDesc
      }
      language {
        slug
      }
      translations {
        uri
      }

      databaseId
      parentDatabaseId
      featuredImage {
        node {
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED

                quality: 70
              )
            }
          }
        }
      }
    }
  }
`
